import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Filler,
  Tooltip,
  Legend,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  BarController,
  CategoryScale,
  PolarAreaController,
  RadarController,
  RadialLinearScale,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import BarChart from "./BarChart.tsx";
import { getFromApiFunction } from "../../services/apiFunctions.tsx";

ChartJS.register(
  LinearScale,
  RadialLinearScale,
  CategoryScale,
  BarElement,
  ArcElement,
  PointElement,
  BarController,
  LineElement,
  RadarController,
  PolarAreaController,
  Filler,
  Tooltip,
  Legend,
);

const scrollBarClasses =
  // "scrollbar-thin scrollbar-thumb-slate-500 scrollbar-track-slate-50 overflow-auto";
  "scrollbar scrollbar-thumb-w-[2px] scrollbar-thumb-rounded-full scrollbar-thumb-gray-400 scrollbar-track-gray-100 flex-grow sm:overflow-auto";

export default function ChartRenderer(props: any) {
  /**
   * In the following base code:
   * - divisionType is a specific type of territory division (departments or poles)
   * - division is a specific division of a given type (Ex: Alibori for departments and PDA1 for poles)
   */

  const { filterParams, statsUnitProps, buildParameter } = props;

  const [value, setValue] = React.useState(0);

  const [statsUnit, setStatsUnit] = useState(statsUnitProps);
  const [thematics, setThematics] = useState<any>([]);

  useEffect(() => {
    getFromApiFunction("thematics/").then((response) => {
      setThematics(response.data);
    });
  }, []);

  useEffect(() => {
    setStatsUnit(statsUnitProps);
  }, [statsUnitProps]);

  useEffect(() => {}, [statsUnit]);

  const width = "relative w-full mb-8 m-auto 2xl:mx-[10px]";

  return (
    <div className={`${scrollBarClasses} h-full align-center`}>
      <div className="sm:grid xl:grid-cols-1 w-4/5 m-auto rounded-md">
        <div className="w-full">
          <h1 className="text-center font-bold mb-4">Analyse générale</h1>
          <div>
            <div className="sm:grid xl:grid-cols-2 gap-8 justify-center lg:justify-normal">
              <div className={width}>
                <BarChart
                  graphName="Nombre de projets par thématique"
                  baseEndpoint="projects/stats/by_thematic/"
                  statsUnitProps={statsUnit}
                  paramsProps={filterParams}
                  // buildParameter={buildParameter}
                  color={"#ebab34"}
                  perRegionData={false}
                  customHeigth={"600px"}
                />
              </div>
              <div className={width}>
                <BarChart
                  graphName={`Nombre de projets par ${statsUnit}`}
                  baseEndpoint="projects/stats/"
                  statsUnitProps={statsUnit}
                  paramsProps={filterParams}
                  // buildParameter={buildParameter}
                  color={"#ebab34"}
                  customHeigth={"600px"}
                />
              </div>
            </div>
            <BarChart
              graphName="Nombre de projets part PTF"
              baseEndpoint="projects/stats/by_ptf/"
              statsUnitProps={statsUnit}
              paramsProps={filterParams}
              // buildParameter={buildParameter}
              color={"#ebab34"}
              chartType={Doughnut}
              perRegionData={false}
              customHeigth={"400px"}
            />
          </div>
          <h1 className="text-center font-bold mb-4">Analyse par thématique</h1>
          <div className="sm:grid xl:grid-cols-2 gap-4 justify-center lg:justify-normal">
            {thematics.map((thematic) => {
              return (
                <div className={width}>
                  <BarChart
                    graphName={`Nombre de projets par région - ${thematic.name}`}
                    baseEndpoint="projects/stats/"
                    statsUnitProps={statsUnit}
                    paramsProps={filterParams}
                    defaultParams={new URLSearchParams([["thematic", thematic.id]])}
                    color={"#ebab34"}
                    customHeigth={"600px"}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
