import { downloadFile } from "../../services/fileServices.tsx";
import BaseModel from "./BaseModel.tsx";

export default class DocumentFile extends BaseModel {
  static url: string = "documents/";
  filename: string;
  size: number;
  created: Date;
  imageUrl: string = "";
  category: string = "";

  constructor(
    id: number,
    name: string,
    filename: string,
    size: number,
    created: Date,
    imageUrl: string = "",
    category: string = "",
  ) {
    super(id, name);
    this.filename = filename;
    this.size = size;
    this.created = created;
    this.imageUrl = imageUrl;
    this.category = category;
  }

  static fromJson(json: any) {
    return new this(
      json.id,
      json.name,
      json.filename,
      json.size,
      json.created,
      json.document_file,
      json.category,
    );
  }

  download() {
    const params = new URLSearchParams();
    downloadFile(`${DocumentFile.url}${this.id}/download/`, this.filename, "");
  }
}
