import getAxiosConfig from "./axios.js";

const axiosInstance = getAxiosConfig();
export const getFromApiFunction = async (
  endPoint: string,
  params: URLSearchParams = new URLSearchParams(),
  headers: any = {},
) => axiosInstance.get(endPoint, { params, ...headers });

export const createDataFunction = async (endPoint: string, payload: any) =>
  axiosInstance.post(endPoint, payload);

export const updateDataFunction = async (endPoint: string, payload: any) =>
  axiosInstance.put(endPoint, payload);

export const deleteDataFunction = async (endPoint: string, payload: any) =>
  axiosInstance.delete(endPoint, payload);
