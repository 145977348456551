import axios from "axios";
import { tokenAccessKey, tokenRefreshKey } from "../constants.tsx";
import { emitCustomEvent } from "react-custom-events";

export const API_URL = process.env.REACT_APP_API_URL;

async function getTokenWithRefresh(originalConfig, axiosInstance) {
  const refreshToken = localStorage.getItem(tokenRefreshKey);
  if (!refreshToken) return;
  const payload = { refresh: refreshToken };
  try {
    const response = await axiosInstance.post("/token/refresh/", payload);
    const newToken = response.data.access;
    localStorage.setItem(tokenAccessKey, newToken);
    originalConfig.headers.Authorization = `Bearer ${newToken}`;
    return originalConfig;
    // eslint-disable-next-line no-shadow
  } catch (error) {
    console.log(error);
  }
}

function getAxiosConfig() {
  // eslint-disable-next-line prefer-const
  let instanceConfig = {
    baseURL: API_URL,
    timeout: 5 * 60000,
    headers: {},
  };

  const axiosInstance = axios.create(instanceConfig);

  axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem(tokenAccessKey);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    const trackDownloadProgress = (event) => {
      const [base, pk, suffix] = config.url.split("/");
      const key = `${base}-${pk}-downloaded`;
      if (event.progress) {
        emitCustomEvent(key, Math.round(event.progress * 100));
        console.log("emitted", event.process);
      }
    };

    // Axios interceptor to monitor download progress
    config.onDownloadProgress = trackDownloadProgress;
    return config;
  });

  axiosInstance.interceptors.response.use(
    (config) => {
      // Intercept get `/token` response and globally set token in axios header.
      if (config?.data?.access && config?.data?.refresh) {
        localStorage.setItem(tokenAccessKey, config.data.access);
        localStorage.setItem(tokenRefreshKey, config.data.refresh);
      }

      return config;
    },
    async (error) => {
      // "ERR_BAD_REQUEST"
      const originalConfig = error.config;
      // Intercept for token expiration error, and globally override token in
      // axios header.
      // On token expiration
      if (error?.response?.status === 401) {
        const config = await getTokenWithRefresh(originalConfig, axiosInstance);

        if (!config) window.location.href = "/login";
        // Authentication info needed on the page so we redirect to login
        //
        return axios(config);
      }
      return Promise.reject(error);
    },
  );

  return axiosInstance;
}

export default getAxiosConfig;
