import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
// import { ChevronDownIcon, CheckIcon } from "@heroicons/react/solid";
import { MAP_VIEWS, MapViewInterface } from "../../constants.tsx";

interface ViewControllerPropsInterface {
  selectedMapView: MapViewInterface;
  // eslint-disable-next-line no-unused-vars
  onMapViewChanged: (value: MapViewInterface) => void;
}

const LISTBOXOPTIONCLASSNAME =
  "absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm";
const LISTBOXBUTTONCLASSNAME =
  "relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function ViewController(props: ViewControllerPropsInterface) {
  const { selectedMapView, onMapViewChanged } = props;

  return (
    <Listbox value={selectedMapView} onChange={onMapViewChanged} horizontal>
      <Listbox.Label className="block text-sm font-medium text-gray-700">
        {" "}
      </Listbox.Label>
      <div className="relative mt-1">
        <Listbox.Button className={LISTBOXBUTTONCLASSNAME}>
          <span className="flex items-center">
            {/* <img
              src={selectedMapView.illustration}
              alt=""
              className="h-6 w-6 flex-shrink-0 rounded-full"
            /> */}
            <span className="ml-3 block truncate">{selectedMapView.viewName}</span>
          </span>
          {/* <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
            <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span> */}
        </Listbox.Button>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className={LISTBOXOPTIONCLASSNAME}>
            {MAP_VIEWS.map((mapView: MapViewInterface) => {
              const isActive = mapView === selectedMapView;
              return (
                <Listbox.Option
                  key={mapView.id}
                  className={({ active = true }) =>
                    classNames(
                      active ? "text-white bg-indigo-600" : "text-gray-900",
                      "relative cursor-default select-none py-2 pl-3 pr-9",
                    )
                  }
                  value={mapView}
                >
                  <>
                    <div className="flex items-center">
                      {/* <img
                        src={mapView.illustration}
                        alt=""
                        className="h-6 w-6 flex-shrink-0 rounded-full"
                      /> */}
                      <span
                        className={classNames(
                          selectedMapView ? "font-semibold" : "font-normal",
                          "ml-3 block truncate",
                        )}
                      >
                        {mapView.viewName}
                      </span>
                    </div>

                    {isActive ? (
                      <span
                        className={classNames(
                          isActive ? "text-white" : "text-indigo-600",
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                        )}
                      >
                        {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                      </span>
                    ) : null}
                  </>
                </Listbox.Option>
              );
            })}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
