import getAxiosConfig from "./axios";

const excel_accept =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export function downloadFile(
  endPoint: string,
  fileName: string,
  accept = excel_accept,
) {
  const props: any = { responseType: "blob" };
  if (accept === excel_accept) props["headers"] = { Accept: accept };
  getAxiosConfig()
    .get(endPoint, props)
    .then((result) => {
      const url = window.URL.createObjectURL(result.data);
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
}
