import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, Link, Navigate, RouterProvider } from "react-router-dom";

import "./index.css";
import App from "./App.tsx";
import Dashboard from "./views/Dashboard.tsx";
import DataTableView from "./views/DataTable.tsx";
import Mapping from "./components/mapping/Mapping.js";
import LoginPage from "./views/login/LoginPage.js";
import { createDataFunction, getFromApiFunction } from "./services/apiFunctions.tsx";
import axios from "axios";
import ReportPage from "./views/reportpage/ReportPage.tsx";

// function LoginForm () {
//   const queryParameters = new URLSearchParams(window.location.search)

//   useEffect(() => {
//     if(queryParameters.has("code")) return
//     axios.get("http://localhost:8000/api/users/sso/login/", {params: {email: "cemel@lmecahouandjinougmail.onmicrosoft.com"}}).then(
//       (response) => {
//         const callback_uri = `${response.data.provider_url}&redirect_uri=${window.location.origin}/login/`;
//         window.location.replace(callback_uri);
//       }
//     )
//   }, [])

//   if (queryParameters.has("code")) {
//     createDataFunction("auth/login/microsoft/", {code: queryParameters.get("code"), client_callback_url: `${window.location.origin}/login/`})
//     .then((response) => {
//       console.log(response)
//     })
//   }
//   return (<div>
//     Login
//     <br/>
//     <Link to="https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?client_id=cae174ed-b68c-467c-9240-f084611eca57&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Flogin%2F&scope=User.Read&response_type=code&state=2hsXtvXgOUhS91Eq">Microsoft</Link>
//     </div>)
// }

const router = createBrowserRouter([
  {
    path: "/app",
    element: <App />,
    children: [
      {
        path: "/app/dashboard",
        element: <Dashboard />,
        index: true,
      },
      {
        path: "/app/data",
        element: <DataTableView />,
      },
      {
        path: "/app/maps",
        element: <Mapping />,
      },
      {
        path: "/app/documents",
        element: <ReportPage />,
      },
    ],
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/",
    element: <Navigate to="/app/dashboard" />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  // <Provider store={store}>
  <div className="bg-primary-light w-full h-full">
    <RouterProvider router={router} />
  </div>,
  // </Provider>,
);
