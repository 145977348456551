/* eslint-disable react/prop-types */
import React from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { MapContainer, GeoJSON, TileLayer } from "react-leaflet";
import { useCustomEventListener } from "react-custom-events";
import country from "../shapes/benin.json";
import { emitCustomEvent } from "react-custom-events";

const SATELITE_VIEW = {
  attribution:
    "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
  url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
};

const DEFAULT_VIEW = {
  attribution:
    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
};

export const MAP_VIEWS = [
  {
    id: "1",
    illustration: "/street.jpg",
    // component: <StreetViewSvg />,
    viewName: "Rues",
    tileLayerInfos: DEFAULT_VIEW,
  },
  {
    id: "2",
    illustration: "/sat.jpg",
    // component: <SateliteViewSvg />,
    viewName: "Satellite",
    tileLayerInfos: SATELITE_VIEW,
  },
];

// To be imported from backend
const layers = [];

export default function RegionSelector(props) {
  const { regions, interventionSet, filterMethod } = props;
  const [view, setView] = React.useState(MAP_VIEWS[0]);
  const [tileKey, setTileKey] = React.useState(MAP_VIEWS[0].id);

  const commonStyle = {
    color: "black",
    weight: 1.3,
  };
  const interventionStyles = {
    ALIBORI: "#abf4c9",
    BORGOU: "#f8dda4",
    DONGA: "#a491d3",
    ATACORA: "#e2c044",
  };
  const deselectedStyle = {
    ...commonStyle,
    fillColor: "gray",
    fillOpacity: 0.3,
  };
  const neutralStyle = {
    ...commonStyle,
    fillColor: "transparent",
    fillOpacity: 1,
  };

  const getInterventionStyle = (layer) => {
    console.log(layer.feature.properties.name.toUpperCase());
    return {
      ...commonStyle,
      fillColor:
        interventionStyles[layer.feature.properties.name.toUpperCase()] ||
        "transparent",
      fillOpacity: 1,
    };
  };

  const setInitialStyle = (layer) => {
    layer.setStyle(getInterventionStyle(layer));
  };

  useCustomEventListener("re-init", () => {
    layers.forEach((layer) => {
      setInitialStyle(layer);
    });
  });

  const onEachFeature = (feature, layer) => {
    layer
      .bindTooltip(feature.properties.name, {
        permanent: true,
        direction: "center",
        className: "my-labels",
      })
      .openTooltip();
    layer.on({
      click: () => {
        setInitialStyle(layer);
        layers.forEach((element) => {
          const elementName = element.feature.properties.name;
          if (elementName !== feature.properties.name) {
            element.setStyle(
              interventionStyles[elementName.toUpperCase()]
                ? deselectedStyle
                : neutralStyle,
            );
          }
        });
        if (filterMethod) {
          filterMethod(feature.properties.name);
        }
        emitCustomEvent("filter", feature.properties.name);
      },
    });
    setInitialStyle(layer);
    layers.push(layer);
  };

  const bounds = L.geoJSON(country).getBounds();
  return (
    <MapContainer
      bounds={bounds}
      zoomControl={false}
      dragging={false}
      zoom={12}
      doubleClickZoom={false}
      scrollWheelZoom={false}
    >
      <GeoJSON data={regions} onEachFeature={onEachFeature} />
      <TileLayer key={tileKey} {...view.tileLayerInfos} />
    </MapContainer>
  );
}
