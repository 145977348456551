import React, { useState, useEffect } from "react";
import SelectComponent from "./SelectComponent.tsx";
import { getFromApiFunction } from "../../services/apiFunctions.tsx";

export default function IndicateurSuivi(props: any) {
  const { isOpen, onClose, setMarkers, addMarkers } = props;

  const [thematics, setThematics] = useState([]);
  const [selectedThematic, setSelectedThematic] = useState();

  const [ptfs, setPtfs] = useState([]);
  const [selectedPtf, setSelectedPtf] = useState();

  const [selectedActor, setSelectedActor] = useState();

  const [projects, setProjects] = useState<any>([]);
  const [organisations, setOrganisations] = useState<any>([]);

  const notNull = (value: any) => {
    return value !== null && value !== undefined;
  };

  const hierarchyList = [setSelectedActor, setSelectedThematic, setSelectedPtf];

  /**
   * When a choice is made on a select the following selects
   * in the form must be reset
   * This method aims to reset the associated state variables
   * @param fromIndex Index when to start the reset from
   */
  const resetVariables = (fromIndex: number) => {
    setMarkers([]);

    for (let index = fromIndex; index < hierarchyList.length; index++) {
      const setter = hierarchyList[index];
      setter(undefined);
    }
  };

  const changeVariable = (value: any, setterIndex: number) => {
    hierarchyList[setterIndex](value);
    resetVariables(setterIndex + 1);
  };

  useEffect(() => {
    getFromApiFunction("thematics/").then((response) => {
      setThematics(response.data);
    });
  }, []);

  const getProjects = (params?) => {
    getFromApiFunction("projects/", params).then((response: any) => {
      setProjects(response.data);
    });
  };

  const getOrganisations = (params?) => {
    getFromApiFunction("organisations/", params).then((response: any) => {
      setOrganisations(response.data);
    });
  };

  useEffect(() => {
    if (!notNull(selectedThematic)) return;

    const params = new URLSearchParams();
    if (selectedThematic && selectedThematic != 0) {
      params.append("thematic", selectedThematic);
    }

    if (selectedActor === "projects") {
      getFromApiFunction("projects/ptfs/", params).then((response: any) => {
        setPtfs(response.data);
      });
    }
    if (selectedActor === "all") {
      getProjects(params);
      getOrganisations(params);
    }
    if (selectedActor === "organisations") {
      getOrganisations(params);
    }
  }, [selectedThematic]);

  useEffect(() => {
    if (!notNull(selectedPtf)) return;

    const params = new URLSearchParams();
    if (selectedThematic && selectedThematic != 0) {
      params.append("thematic", selectedThematic);
    }

    if (selectedPtf && selectedPtf != "all") {
      params.append("financial_partner", selectedPtf);
    }

    getProjects(params);
  }, [selectedPtf]);

  useEffect(() => {
    if (addMarkers && projects) {
      addMarkers(
        projects.filter((item) => item.longitude && item.latitude),
        "project",
      );
    }
  }, [projects]);

  useEffect(() => {
    if (addMarkers && organisations) {
      addMarkers(
        organisations.filter((item) => item.longitude && item.latitude),
        "organisation",
      );
    }
  }, [organisations]);

  return (
    <div
      className={`overlay-inner absolute left-[50px] top-[250px] ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div className="tracking-inner absolute">
        <div
          className="lc-modal absolute left-0 top-0 w-fit sm:w-[400px] bg-black/50 p-5 z-[999] transition-pacofide"
          data-lc-target="tracking"
        >
          <button
            className="lc-modal-icon-cross absolute right-3 top-3 flex items-center justify-center h-[30px] w-[30px] hover:bg-gray-200 text-white text-2xl z-[2] cursor-pointer rounded-full transition-pacofide"
            data-lc-target="#tracking"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style={{ fill: "currentColor" }}
            >
              <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
            </svg>
          </button>
          <div className="space-y-12">
            <form className="relative text-white">
              <h3 className="flex items-center justify-center space-x-4 text-base font-medium">
                Éléments d'observation
              </h3>
              <div className="space-y-6 mt-6 overflow-y-auto overflow-x-hidden max-h-[300px] md:max-h-none">
                <div className="sm:space-y-6 sm:block sm:w-full">
                  <label className="items-center sm:flex">
                    <span className="block sm:min-w-[100px] text-sm">Acteur</span>

                    <SelectComponent
                      options={[
                        { label: "Tout", value: "all" },
                        { label: "Projets/PTF", value: "projects" },
                        { label: "ONG", value: "organisations" },
                      ]}
                      onChange={(value) => changeVariable(value, 0)}
                      customPlaceHolder="Sélectionner un acteur"
                      value={selectedActor}
                    />
                  </label>

                  <label className="items-center sm:flex">
                    <span className="block sm:min-w-[100px] text-sm">Thématique</span>

                    <SelectComponent
                      options={[
                        { label: "Tout", value: 0 },
                        { label: "Sans thématique", value: "none" },
                        ...thematics.map(({ name, id }) => ({
                          label: name,
                          value: id,
                        })),
                      ]}
                      onChange={(value) => changeVariable(value, 1)}
                      customPlaceHolder="Sélectionner une thématique"
                      value={selectedThematic}
                    />
                  </label>

                  {selectedActor === "projects" && notNull(selectedThematic) && (
                    <label className="items-center sm:flex">
                      <span className="block sm:min-w-[100px] text-sm">PTF</span>

                      <SelectComponent
                        options={[
                          { label: "Tout", value: "all" },
                          ...ptfs.map((value) => ({ label: value, value })),
                        ]}
                        onChange={(value) => changeVariable(value, 2)}
                        customPlaceHolder="Sélectionner une organisation"
                        // className="w-full"
                        value={selectedPtf}
                      />
                    </label>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
