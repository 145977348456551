import React, { useState } from "react";
import "./LoginStyle.scss";
import axios from "../../services/axios.js";
import { Link, useLocation, useNavigate } from "react-router-dom";
import userIcon from "../../assets/img/user_icon.svg";
import lockIcon from "../../assets/img/lock_icon.svg";
import gotoLink from "../../assets/img/gotoLink.svg";
import mmkouas from "../../assets/img/2mkouas.svg";
import { tokenAccessKey, tokenRefreshKey } from "../../constants.tsx";
import getAxiosConfig from "../../services/axios.js";

export default function LoginPage() {
  const navigate = useNavigate();
  const [data, setData] = useState({
    username: "",
    password: "",
  });

  const [processing, setProcessing] = useState(false);
  const [hasError, sethasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { username, password } = data;

  const changeHandler = (e) => {
    setData({ ...data, [e.target.name]: [e.target.value] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);

    var payload = {
      username: username[0],
      password: password[0],
    };
    getAxiosConfig()
      .post("token/", payload)
      .then((response) => {
        setProcessing(false);
        navigate("/app/dashboard/");
      })
      .catch((error) => {
        if (error.response) {
          var errorMessage = error.response.data.detail;
          setErrorMessage(errorMessage);
        } else if (error.request) {
          setErrorMessage("Connexion issue.");
        } else {
          // Something happened in setting up the request that triggered an Error
          setErrorMessage("Something went wrong. Contact us");
        }
        sethasError(true);
        setProcessing(false);
      });
  };

  const renderButtonContent = () => {
    return processing ? <Spinner /> : "connexion";
  };

  return (
    <div className="LoginPage">
      <div className="app-features">
        {/* <div className="main-feature-title">
          <div>AGORA</div>
        </div> */}
      </div>

      <div className="login-space">
        <div className="login-container">
          <div className="partners pt-2 center">
            <img
              src="/logo.jpeg"
              alt="logo cascade projet"
              className="h-36 min-w-[60px] m-auto"
            />
          </div>

          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="">
                <img src={userIcon} alt="USER_ICON" width={15} height={15} />
              </label>
              <input
                type="text"
                placeholder="Identifiant"
                id="username"
                name="username"
                value={username}
                onChange={changeHandler}
                required
              />
            </div>
            <div>
              <label htmlFor="">
                <img src={lockIcon} alt="LOCK_ICON" width={15} height={15} />
              </label>
              <input
                type="password"
                placeholder="Mot de passe"
                id="password"
                name="password"
                value={password}
                onChange={changeHandler}
                required
              />
            </div>
            <Link to="#">Mot de passe oublié?</Link>
            {hasError ? (
              <p className="text-center text-danger">{errorMessage}</p>
            ) : null}
            <div className="submit-button-container">
              <button type="submit">{renderButtonContent()}</button>
            </div>
          </form>
        </div>

        <div className="developers-firm">
          <div>
            <span>GéoDigital</span>
            <Link to="#">
              <img src={gotoLink} alt="GO_TO" width={15} height={15} />
            </Link>
          </div>
          <div>
            <img src={mmkouas} width={60} />
            <Link to="#">
              <img src={gotoLink} alt="GO_TO" width={15} height={15} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export function Spinner() {
  return (
    <div className="d-flex justify-content-center">
      <div className="spinner-border text-light" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
