/* eslint-disable react/prop-types */
import React from "react";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { downloadFile } from "../services/fileServices.tsx";

function Table({ tableData, columns, fixedHeight = true, path, downloadFileName }) {
  const muiCache = createCache({
    key: "mui",
    prepend: true,
  });

  const scrollBarClasses =
    "scrollbar-thin scrollbar-thumb-slate-500 scrollbar-track-slate-50 overflow-auto";

  const getMuiTheme = () =>
    createTheme({
      palette: {
        primary: {
          main: "#788c82",
        },
        secondary: {
          main: "#eb70d3",
        },
        common: {
          black: "#788c82",
        },
      },
      shape: {
        borderRadius: 20,
      },
      components: {
        // MuiTable: {
        //   styleOverrides: {
        //     root: {
        //       backgroundColor: "#AAF",
        //     },

        //     // paper: {
        //     //   boxShadow: "none",
        //     // },
        //   }
        // },
        // MuiTable: {
        //   root: {
        //     backgroundColor: "#FFF",
        //   },
        // },
        // MuiToolbar: {
        //   styleOverrides: {
        //     root: {
        //       backgroundColor: "#f00",
        //     },
        //   }
        // },
        // MuiTableCell: {
        //   styleOverrides: {
        //     head: {
        //       backgroundColor: "purple",
        //     },
        //   }
        // },
        // MUIDataTableSelectCell: {
        //   headerCell: {
        //     backgroundColor: "blue",
        //   },
        // },
        MuiTableRow: {
          styleOverrides: {
            root: {
              "&:nth-child(even)": {
                backgroundColor: "rgb(220 252 231)", // alternate row color
              },
            },
          },
        },
        MuiTableHead: {
          styleOverrides: {
            root: {
              backgroundColor: "rgb(220 252 231)",
            },
          },
        },
      },
    });

  const options = {
    // filterType: "checkbox",
    selectableRows: false,
    pagination: true,
    tableBodyHeight: fixedHeight ? "calc(100vh - 270px)" : "",
    filterType: "dropdown",
    responsive: "standard",
    fixedHeader: true,
    onDownload: (buildHead, buildBody, columns, data) => {
      console.log(path);
      downloadFile(
        path,
        downloadFileName,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      );
      // We cancel default file downloading to get data from server instead
      return false;
    },
    textLabels: {
      filter: {
        all: "Tout",
        title: "Filtres",
        reset: "Réinitialiser",
      },
      pagination: {
        next: "Page Suivante",
        previous: "Page Précédente",
        rowsPerPage: "Nbre d'éléments par page:",
        displayRows: "sur",
      },
      toolbar: {
        search: "Rechercher",
        downloadCsv: "Télécharger",
        print: "Imprimer",
        viewColumns: "Filtrer colonnes",
        filterTable: "Filtrer lignes",
      },
      viewColumns: {
        title: "Colonnes à afficher",
        titleAria: "Afficher/Cacher colonnes",
      },
    },
  };

  return (
    <div>
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            class={scrollBarClasses}
            title=""
            data={tableData || ["Aucune donnée"]}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </CacheProvider>
    </div>
  );
}

export default Table;
