import React from "react";
import { useState } from "react";
import RegionSelector from "../components/RegionSelector";
import { emitCustomEvent } from "react-custom-events";
import departements from "../shapes/departement.json";
import ChartRenderer from "../components/graphs/ChartsRenderer.tsx";

export default function Dashboard() {
  const [params, setFilterParams] = React.useState<URLSearchParams>();
  const [statsUnit, setStatsUnit] = React.useState("departement");
  const [openMap, setOpenMap] = useState(false);

  const reInit = () => {
    emitCustomEvent("re-init");
    setFilterParams(new URLSearchParams());
    setStatsUnit("departement");
  };

  const filterByDivision = (divisionName: string) => {
    const params = new URLSearchParams([["departement", divisionName]]);
    setFilterParams(params);
    setStatsUnit("commune");
  };

  return (
    <div className="flex flex-row h-full">
      <button
        id="map"
        type="button"
        className="h-14 w-14 fixed right-6 bottom-10 z-40 flex lg:hidden items-center justify-center rounded-full bg-green-500 hover:bg-green-600 text-white cursor-pointer opacity-1 pointer-events-auto transition-all ease-in-out duration-300"
        onClick={() => setOpenMap(true)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          style={{ fill: "currentColor" }}
          className="h-8 w-8"
        >
          <path d="m21.447 6.105-6-3a1 1 0 0 0-.895 0L9 5.882 3.447 3.105A1 1 0 0 0 2 4v13c0 .379.214.725.553.895l6 3a1 1 0 0 0 .895 0L15 18.118l5.553 2.776a.992.992 0 0 0 .972-.043c.295-.183.475-.504.475-.851V7c0-.379-.214-.725-.553-.895zM10 7.618l4-2v10.764l-4 2V7.618zm-6-2 4 2v10.764l-4-2V5.618zm16 12.764-4-2V5.618l4 2v10.764z"></path>
        </svg>
      </button>
      <div className="relative h-[70%] sm:h-full w-full sm:w-[300px] hidden lg:block">
        <RegionSelector
          filterMethod={filterByDivision}
          regions={departements}
          interventionSet={[]}
        />
        <button
          className="absolute top-1 left-1 w-[30px] h-[30px] z-[999] text-xl font-bold bg-white"
          onClick={reInit}
        >
          &#x21ba;{" "}
        </button>
      </div>
      <div className="sm:p-4 h-full flex-grow max-w-[100%] lg:max-w-[80%]">
        <ChartRenderer
          filterParams={params}
          statsUnitProps={statsUnit}
          params={params}
        />
      </div>
      {/* Here comes Map modal */}
      {openMap && (
        <div
          className="lc-modal fixed left-0 top-0 w-[300px] h-full bg-white pt-5 z-[99999] transition-pacofide"
          data-lc-target="map"
        >
          <span
            className="lc-modal-icon-cross absolute right-3 top-3 flex items-center justify-center h-[30px] w-[30px] hover:bg-gray-200 text-slate-500 text-2xl z-[2] cursor-pointer rounded-full transition-pacofide"
            data-lc-target="#map"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style={{ fill: "currentColor" }}
              onClick={() => setOpenMap(false)}
            >
              <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
            </svg>
          </span>

          <div className="mt-6 h-[95%]">
            <div className="relative h-full">
              <RegionSelector
                filterMethod={filterByDivision}
                regions={departements}
                interventionSet={[]}
              />
              <button
                className="absolute top-1 left-1 w-[30px] h-[30px] z-[999] text-xl font-bold bg-white"
                onClick={reInit}
              >
                &#x21ba;{" "}
              </button>
            </div>
          </div>
        </div>
      )}
      <div
        className={`${
          openMap ? "" : "hidden"
        } lc-modal-overlay fixed left-0 top-0 h-full w-full bg-black/50 z-[9999] transition-pacofide`}
        data-lc-target="map"
        onClick={() => setOpenMap(false)}
      ></div>
    </div>
  );
}
