import { useEffect } from "react";
import $ from "jquery";
import { useNavigate } from "react-router-dom";

export default function SideBar() {
  const navigate = useNavigate();

  useEffect(() => {
    $(".lc-toggle").click(function () {
      $(".lc-sidebar").toggleClass("lc-close");

      $(".lc-section").toggleClass("lc-close-group");

      if ($(".lc-sidebar").hasClass("lc-close")) {
        $(".lc-sidebar").addClass("md:w-[78px] w-[250px]");

        $(".lc-sidebar").removeClass("md:w-[250px] w-[78px]");
      } else {
        $(".lc-sidebar").removeClass("md:w-[78px] w-[250px]");

        $(".lc-sidebar").addClass("md:w-[250px] w-[78px]");
      }
    });

    /***** ===== Toggle Menu Script End ===== *****/

    /**
     =================================================
     Filter Script Begin
     =================================================
     +*/

    $(".lc-filter").click(function () {
      if ($(".lc-filter-container").is(":hidden")) {
        $(".lc-filter-container").slideDown("fast");
      } else {
        $(".lc-filter-container").animate(
          {
            overflow: "hidden",
            marginTop: "-10px",
          },
          150,
          function () {
            $(".lc-filter-container").attr("style", "display: none;");
          },
        );
      }
    });
  }, []);

  const menus = [
    {
      name: "Accueil",
      icon: (
        <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z"></path>
      ),
      to: "dashboard",
    },
    {
      name: "Mapping",
      icon: (
        <path d="m21.447 6.105-6-3a1 1 0 0 0-.895 0L9 5.882 3.447 3.105A1 1 0 0 0 2 4v13c0 .379.214.725.553.895l6 3a1 1 0 0 0 .895 0L15 18.118l5.553 2.776a.992.992 0 0 0 .972-.043c.295-.183.475-.504.475-.851V7c0-.379-.214-.725-.553-.895zM10 7.618l4-2v10.764l-4 2V7.618zm-6-2 4 2v10.764l-4-2V5.618zm16 12.764-4-2V5.618l4 2v10.764z"></path>
      ),
      to: "maps",
    },
    {
      name: "Tableau de Données",
      icon: (
        <path d="M10 3H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM9 9H5V5h4v4zm5 2h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1zm1-6h4v4h-4V5zM3 20a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v6zm2-5h4v4H5v-4zm8 5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v6zm2-5h4v4h-4v-4z"></path>
      ),
      to: "data",
    },
    {
      name: "Librairie",
      icon: (
        <path d="M19 2.01H6c-1.206 0-3 .799-3 3v14c0 2.201 1.794 3 3 3h15v-2H6.012C5.55 19.998 5 19.815 5 19.01c0-.101.009-.191.024-.273.112-.575.583-.717.987-.727H20c.018 0 .031-.009.049-.01H21V4.01c0-1.103-.897-2-2-2zm0 14H5v-11c0-.806.55-.988 1-1h7v7l2-1 2 1v-7h2v12z"></path>
      ),
      to: "documents",
    },
  ];

  return (
    <>
      <div className="lc-sidebar group/child peer fixed h-full w-[78px] md:w-[250px] px-[10px] py-[14px] bg-white border-r-2 z-[100] transition-[left, width] duration-300 ease-linear">
        <header className="relative">
          <div className="flex flex-col space-y-3 items-center space-x-2">
            <span className="min-w-[60px] w-full flex items-center justify-center bg-green-400">
              <img
                src="/image.png"
                alt="logo cascade projet"
                // className="h-12 w-12 min-w-[60px] w-full"
              />
            </span>
          </div>

          <span className="lc-toggle rotate-180 md:rotate-0 group-[.lc-close]/child:rotate-0 md:group-[.lc-close]/child:rotate-180 absolute top-1/2 -right-6 hidden sm:flex items-center justify-center h-[25px] w-[25px] bg-green-500 text-white cursor-pointer text-[22px] -translate-y-1/2 rounded-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style={{ fill: "currentColor" }}
            >
              <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"></path>
            </svg>
          </span>
        </header>

        <div className="h-[calc(100%-55px)] flex flex-col justify-between overflow-x-hidden overflow-y-scroll">
          <div className="mt-10">
            <ul>
              {menus.map((menu) => (
                <li className="flex items-center h-12">
                  <a
                    onClick={() => navigate(menu.to)}
                    className={`cursor-pointer group flex items-center h-full w-full rounded-md transition-all ease-in-out duration-300 ${window.location.href.includes(menu.to) ? "bg-green-100" : ""}`}
                  >
                    <span className="flex items-center justify-center h-full min-w-[60px] text-2xl text-green-500 rounded-md transition-all ease-in-out duration-300">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        style={{ fill: "currentColor" }}
                      >
                        {menu.icon}
                      </svg>
                    </span>

                    <span className="text-[13px] md:text-[15px] text-green-500 font-semibold whitespace-nowrap transition-all ease-in-out duration-300">
                      {menu.name}
                    </span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
