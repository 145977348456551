import React from "react";

import SideBar from "./components/SideBar";
import "./App.scss";
import { getMe } from "./services/me";
import { Navigate, Outlet } from "react-router-dom";

function App() {
  const [user, setUser] = React.useState();

  // React.useEffect(() => {
  //   getMe().then((result) => {
  //     setUser(result)
  //   })
  // }, [])

  return (
    <div id="app">
      <SideBar />
      <section className="lc-section relative group top-0 left-[78px] md:left-[250px] peer-[.lc-close]:left-[250px] md:peer-[.lc-close]:left-[78px] min-h-screen w-[calc(100%-78px)] md:w-[calc(100%-250px)] peer-[.lc-close]:w-[calc(100%-250px)] md:peer-[.lc-close]:w-[calc(100%-78px)] bg-white transition-all ease-in-out duration-300">
        <nav className="lc-admin-head fixed left-[78px] md:left-[250px] group-[.lc-close-group]:left-[250px] md:group-[.lc-close-group]:left-[78px] flex items-center justify-between h-16 md:h-20 w-[calc(100%-78px)] md:w-[calc(100%-250px)] group-[.lc-close-group]:w-[calc(100%-250px)] md:group-[.lc-close-group]:w-[calc(100%-78px)] bg-white z-[90] px-4 md:px-10 shadow-md transition-all ease-in-out duration-300">
          <div className="flex items-center space-x-2 text-slate-600 text-base md:text-xl w-full">
            <div className="font-medium text-center w-full text-sm sm:text-2xl">
              Plateforme géospatiale des PTFs de la zone d'intervention du programme{" "}
              <span className="text-violet-600">AGORA</span>
            </div>
          </div>
        </nav>

        <div className="sm:px-4 md:px-10 pt-20 md:pt-28 h-screen">
          <Outlet />
        </div>
      </section>
      {(window.location.href.endsWith("app/") ||
        window.location.href.endsWith("app")) && <Navigate to="dashboard" />}
    </div>
  );
}

export default App;
